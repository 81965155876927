<script>
	import { user, dbPageKey, dbQuestion } from "../database";
	import { getParams } from "../utils";

	let disabled = true;
	let question = "";
	let loading = false;

	$: {
		if (question.trim().length) disabled = false;
		else disabled = true;
	} 

	let onSubmit = async () => {
		loading = true;
		let questionData = {
			created_by: user.userName,
			created_by_id: user.id,
			question: question,
		};
		await dbQuestion().set(questionData);
		if (!!getParams("publishMode")) {
			window?.DIVE_APP.sendEvent({
				type: "sidebar_app",
				payload: {
					questionData: questionData,
				}
			})
		}
		await dbPageKey().set("VOTE_SCREEN");
	};
</script>

<div class="container">
	<div class="createPollGame">
		<div class="heading">
			<span class="heading-text">Ask your Question</span>
		</div>

		<div class="body-container">
			<div class="questionArea">
				<div class="quesWraper">
					<textarea
						name="question"
						class="question-ques"
						placeholder="Question"
						required
						maxlength="500"
						bind:value={question}
					/>
					<span class="text-warning" class:text-warning-show={question?.length > 450}
						>{question?.length} / 500</span
					>
				</div>
			</div>

		</div>
		<div class="footer">
			<div class="information">
				<span class="info-0"> Only one question allowed* </span>
				<span class="info-2">Your answer will be shown publicly in the session</span>
			</div>
			<button {disabled} on:click={onSubmit} class="button">
				{#if loading}
					<img src="/assets/svg/loading.svg" alt="loading icon" class="loading" />
				{:else}
					<span class="btn-text">Publish</span>
				{/if}
			</button>
		</div>
	</div>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.createPollGame {
		width: calc(100% - 32px);
		height: auto;
		max-width: 560px;
		max-height: 90%;

		margin-left: auto;
		margin-right: auto;
		padding: 24px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;
	}

	.heading {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 16px;
	}
	.heading-text {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 27px;
		text-align: center;
		color: #6c44a8;
	}

	.body-container {
		width: 100%;
		height: calc(100% - 90px);
		display: flex;
		flex-direction: column;
	}

	.questionArea {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
	}
	.quesWraper {
		width: 100%;
		height: auto;
		margin: 0 12px 0 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
	.question-ques {
		width: 100%;
		height: 90px;

		padding: 12px 16px;
		margin: 0 0 4px 0;

		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;

		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;

		resize: none;
	}
	.question-ques:hover {
		border: 1px solid #6c44a8;
	}
	.text-warning {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		text-align: right;

		align-self: flex-end;

		margin-right: 4px;

		color: #f5685c;
		display: none;
	}
	.text-warning-show {
		display: inline;
	}
	.info-0 {
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: rgba(0, 0, 0, 0.4);
	}
	.info-2 {
		font-weight: bold;
	}
	:global(.borderChange:focus) {
		outline: none !important;
		border: solid 2px #f5685c !important;
	}

	.footer {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0px;

		margin: 0px;
	}
	.information {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		font-family: Manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 24px;
		color: #6c44a8;

		margin: 0 10px 0 0;
	}
	.button {
		width: 153px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #6c44a8;
		border-radius: 8px;
		margin: 0;
		cursor: pointer;
	}
	.button:disabled {
		/* Using rgba instead of opacity as opacity was somehow bleeding into the mode picker modal */
		background: rgba(108, 68, 168, 0.5);
		cursor: no-drop;
	}
	.btn-text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		color: #ffffff;
	}
	::-webkit-scrollbar {
		width: 8px;
		height: 122px;
	}
	::-webkit-scrollbar-thumb {
		background: #6c44a8;
		border-radius: 15px;
	}
	::-webkit-scrollbar-track {
		background: transparent;
		margin-left: 10px;
	}

	::-webkit-scrollbar-track-piece:end {
		background: transparent;
		margin-bottom: 10px;
	}

	::-webkit-scrollbar-track-piece:start {
		background: transparent;
		margin-top: 10px;
	}
	::placeholder {
		color: rgba(0, 0, 0, 0.4);
		opacity: 1;
	}
	@media only screen and (max-width: 500px) {
		.createPollGame {
			padding: 16px;
			transform: scale(0.9);
		}
		.questionArea {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		.quesWraper {
			width: 100%;
			margin: 0;
		}
		.footer {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;
		}

		.information {
			line-height: 16px;
		}
		::-webkit-scrollbar {
			width: 4px;
		}

		.button {
			height: 35px;
		}
	}
</style>
