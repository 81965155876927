<script>
</script>

<div class="container">
	<div class="innerContainer">
		<video class="vid" autoplay loop muted playsinline>
			<source src="/assets/video/poll.mp4" type="video/mp4" />
		</video>
		<div class="message">Please wait.. the host is creating a question</div>
	</div>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.innerContainer {
		width: calc(100% - 32px);
		height: 336px;
		max-width: 269px;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 16px;

		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;
	}
	.message {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		text-align: center;

		color: #161616;
		margin: 16px 0 0 0;
	}
	.vid {
		width: 237px;
		height: 237px;
		border-radius: 8px;
	}
</style>
