<script>
	import { onMount } from "svelte";
	import {
		user,
		dbUsers,
		dbAnswersPicked,
		dbQuestion,
		listenOnFirebaseKey,
		dbGameSessionRoundValue,
	} from "../database";
	import { usersStore } from "../store";
	import Result from "../components/Result.svelte";
	import Vote from "../components/Vote.svelte";
	
	let resultofQues;
	let ownVote;
	let totalScore;
	let users;
	let voters;

	let questionData;
	let isCreator;
	let hasVoted = false;

	let resultState;

	let generateResultforQues = (val, users) => {
		let resultArray = [];
		let count = 0;
		Object.entries(val).forEach(([key, value]) => {
			let userId = key;
			let { answer, timestamp } = value;
			let userName = users ? users[key]?.userName || "" : $usersStore[key]?.userName || "";
			let profilePicture = users ? users[key]?.profilePicture || "" : $usersStore[key]?.profilePicture || "";
			resultArray = [...resultArray, { userId, answer, timestamp, profilePicture, userName }];
			resultArray.sort((a, b) => a.timestamp - b.timestamp);
			count++;
		});
		totalScore = count;
		return resultArray;
	};

	$: {
		if (questionData) {
			if (questionData.created_by_id == user.id) isCreator = true;
			else isCreator = false;
		}
	}

	onMount(async () => {
		listenOnFirebaseKey(dbQuestion(), val => (questionData = val));
		listenOnFirebaseKey(dbAnswersPicked(), async val => {
			voters = Object.keys(val);
			resultofQues = generateResultforQues(val, users);
			if (val[user.id]) {
				ownVote = val[user.id];
				resultState = {
					resultofQues,
					ownVote,
					users,
					totalScore,
					isCreator,
					voters,
				};
				hasVoted = true;
			}
		});
		listenOnFirebaseKey(dbUsers, val => {
			if (val) users = val;
		});
	});
</script>

{#if questionData}
	<div class="container">
		<div class="innerContainer">
			{#if hasVoted}
				<Result {questionData} {resultState} />
			{:else}
				<Vote {questionData} />
			{/if}
		</div>
	</div>
{/if}

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.innerContainer {
		width: calc(100% - 32px);
		height: auto;
		max-width: 560px;
		max-height: 90%;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
</style>
